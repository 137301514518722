import React from 'react';
import { useRouter } from 'next/router';
import {
	CUSTOM_PAGES_CONTAINER_TYPE_ID,
	IAssetEntry,
	IGenericContentPageEntry,
} from '../../../contentful';
import { getParentLink } from '../../../navigation';
import { useCommonData } from '../../../common-data/useCommonData';
import { Header } from './Header';
import { CustomPageId } from '../../../contentful/routes';
import { ILocalWeather } from '../weather/ILocalWeather';
import { useTranslation } from '../../../i18n/translate/useTranslation';

export interface IPageHeaderProps {
	page?: IGenericContentPageEntry;
	video?: IAssetEntry;
	localWeather?: ILocalWeather;
	hasPageTitle?: boolean;
	// Introducing `isModern` prop: eventually all pages should be modern, but for now we need to support both
	isModern?: boolean;
	widget?: React.ReactNode;
	setSearchActive: (searchActive: boolean) => void;
	searchActive: boolean;
	showSecureWebpage?: boolean;
	content?: React.ReactNode;
}

export const PageHeader = ({
	page,
	video,
	localWeather,
	hasPageTitle,
	isModern,
	widget,
	setSearchActive,
	searchActive,
	showSecureWebpage,
	content,
}: IPageHeaderProps) => {
	const { pathToPageIdMap } = useCommonData();
	const { query } = useRouter();
	const { t } = useTranslation();

	const isHomePage = page?.fields.id === CustomPageId.Home;

	const title =
		query.destinationAirport === undefined
			? page?.fields.title
			: 'Flight Information';

	const parentLink = page ? getParentLink(page, pathToPageIdMap) : '';

	const backLink =
		(query.destinationAirport !== undefined && '/flights') ||
		(query.poiId !== undefined && '/shop-dine-relax') ||
		(query.shuttleStopId !== undefined &&
			`/shuttle-stop/?id=${query.shuttleStopId}`) ||
		(parentLink !== undefined && parentLink) ||
		'/';

	const backLinkText =
		(query.destinationAirport !== undefined && t('flights_page_title')) ||
		(query.poiId !== undefined && t('shop_dine_relax_page_title')) ||
		(page?.fields.parentTitle && page?.fields.parentTitle) ||
		(page?.fields.parent?.contentType !== CUSTOM_PAGES_CONTAINER_TYPE_ID &&
			page?.fields.parent?.fields.title) ||
		'Home';

	return (
		<Header
			video={video}
			isHomePage={isHomePage}
			hasPageTitle={hasPageTitle}
			backLink={backLink}
			backLinkText={backLinkText}
			headerCallToAction={page?.fields.headerCallToAction}
			headerImage={page?.fields.headerImage}
			title={title}
			localWeather={localWeather}
			isModern={isModern}
			widget={widget}
			setSearchActive={setSearchActive}
			searchActive={searchActive}
			showSecureWebpage={showSecureWebpage}
			content={content}
		/>
	);
};
