import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
	ArrowLinkButton,
	ButtonWrap,
	ChevronLeftIcon,
	ContentWrap,
	ITheme,
	LinkText,
	Responsive,
	SecurityPreferencesIcon,
	StyledArrowLinkButton,
	StyledButtonText,
	StyledIconLink,
	StyledLinkText,
	themed,
} from '@m2/blueprint-ui';
import fallbackHeaderImage from '../../../assets/default-header-image-desktop.jpg';
import {
	NAVIGATION_PADDING_L,
	NAVIGATION_PADDING_M,
	NAVIGATION_PADDING_S,
	NAVIGATION_PADDING_XL,
} from './components/constants';
import { useCommonData } from '../../../common-data/useCommonData';
import { CustomNextLink } from '../link/CustomNextLink';
import { getUrlForLink } from '../../../navigation';
import {
	IAssetEntry,
	IExternalLinkEntry,
	IInternalLinkEntry,
} from '../../../contentful';
import { Color } from '../../../styles/Color';
import { TopNavigation } from './components';
import { LocalWeather } from '../weather/LocalWeather';
import { ILocalWeather } from '../weather/ILocalWeather';
import { useScroll } from '../../../common/hooks/useScroll';
import { sharedWrapperPadding } from '../wrappers/sharedWrapperPadding';
import { useBackButtonLogic } from './hooks/useBackButtonLogic';
import { useTranslation } from '../../../i18n/translate/useTranslation';

export interface IHeaderProps {
	headerCallToAction?: IInternalLinkEntry | IExternalLinkEntry;
	headerImage?: IAssetEntry;
	title?: string;
	backLink: string;
	backLinkText: string;
	video?: IAssetEntry;
	isHomePage?: boolean;
	hasPageTitle?: boolean;
	isErrorPage?: boolean;
	localWeather?: ILocalWeather;
	isModern?: boolean;
	widget?: ReactNode;
	showSecureWebpage?: boolean;
	setSearchActive: (searchActive: boolean) => void;
	searchActive: boolean;
	content?: ReactNode;
}

export const Header = ({
	headerCallToAction,
	headerImage,
	title,
	backLink,
	backLinkText,
	video,
	localWeather,
	widget,
	setSearchActive,
	isHomePage = false,
	hasPageTitle = true,
	isErrorPage = false,
	isModern = false,
	searchActive = false,
	showSecureWebpage = false,
	content,
}: IHeaderProps) => {
	const { pathToPageIdMap } = useCommonData();
	const isScrolledDown = useScroll();
	const backButton = useBackButtonLogic(backLink, backLinkText);
	const { t } = useTranslation();

	return (
		<>
			{!isErrorPage && (
				<>
					<StyledTopNavigationWrapper>
						<TopNavigation
							setSearchActive={setSearchActive}
							hasHeaderImage={!!headerImage}
						/>
						{!searchActive && widget && widget}
					</StyledTopNavigationWrapper>
					{title && (
						<StyledHeaderWrapper
							isImageHeader={!!headerImage}
							isCtaHeader={!!headerCallToAction}
							isHomePage={isHomePage}
							isModern={isModern}
						>
							{headerImage && (
								<>
									<StyledHeaderImage
										src={headerImage.fields.file.url || fallbackHeaderImage}
										alt=""
									/>
									<StyledHeaderImageOverlay
										isCtaHeader={!!headerCallToAction}
										isHomePage={isHomePage}
										isModern={isModern}
									/>
								</>
							)}
							{!!video && (
								<>
									<StyledHeaderVideo
										loop
										controls={false}
										muted
										autoPlay
										playsInline
									>
										<source src={video.fields.file.url} type="video/mp4" />
									</StyledHeaderVideo>
									<StyledHeaderImageOverlay isHomePage />
								</>
							)}

							{headerCallToAction && (
								<StyledCtaHeadingWrapper isModern={isModern}>
									<h3>{title}</h3>
									<ButtonWrap>
										<ArrowLinkButton
											href={getUrlForLink(headerCallToAction, pathToPageIdMap)}
										>
											{headerCallToAction?.fields.title}
										</ArrowLinkButton>
									</ButtonWrap>
								</StyledCtaHeadingWrapper>
							)}
							{!headerCallToAction && (
								<>
									<StyledHeadingWrapper
										isModern={isModern}
										isImageHeader={!!headerImage}
										isHomePage={isHomePage}
										isScrolledDown={isScrolledDown}
									>
										<StyledContentWrap>
											{!isHomePage && (
												<StyledBackLink isImageHeader={!!headerImage}>
													<CustomNextLink>
														<StyledIconLink
															inverted={!!headerImage}
															href={backButton.url}
														>
															<ChevronLeftIcon
																variation={headerImage ? 'inverted' : 'brand'}
															/>
															<LinkText>{backButton.title}</LinkText>
														</StyledIconLink>
													</CustomNextLink>
												</StyledBackLink>
											)}
											{isHomePage && (
												<StyledHomeSubtitle>
													{t('homepage_header_welcome_text')}
												</StyledHomeSubtitle>
											)}
											{hasPageTitle && (
												<StyledPageTitle
													isImageHeader={!!headerImage}
													isHomePage={isHomePage}
													isModern={isModern}
												>
													{title}
												</StyledPageTitle>
											)}

											{content}
											{localWeather && <LocalWeather {...localWeather} />}
										</StyledContentWrap>
										{showSecureWebpage && (
											<StyledWebpageIsSecure
												href="https://seal.digicert.com/seals/popup/?tag=J_yQf6Zj&url=www.dfwairport.com&lang=en"
												target="_blank"
												rel="noopener"
											>
												Secure Web Page
												<StyledSecurityShieldIcon />
											</StyledWebpageIsSecure>
										)}
									</StyledHeadingWrapper>
								</>
							)}
						</StyledHeaderWrapper>
					)}
				</>
			)}
		</>
	);
};

export interface IStyledProps {
	isImageHeader?: boolean;
	mobileNavVisibility?: boolean;
	isCtaHeader?: boolean;
	isHomePage?: boolean;
	isScrolledDown?: boolean;
	isModern?: boolean;
}

const StyledHomeSubtitle = styled.div`
	font-size: 18px;
	font-weight: normal;
	color: white;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 14px;
		margin-bottom: 0;
	}
`;

const StyledCtaHeadingWrapper = styled.div<{ isModern?: boolean }, ITheme>`
	display: flex;
	flex-direction: column;
	margin-top: 340px;
	margin-left: ${NAVIGATION_PADDING_XL};
	width: 80%;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	z-index: 1;
	${({ isModern }) =>
		!isModern
			? css`
					margin-top: 340px;
			  `
			: css`
					margin-top: 180px;
			  `}

	overflow: hidden;

	h3 {
		color: #fff;
		line-height: 80px;
		font-size: 56px;
		font-weight: normal;
		margin-bottom: 24px;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			font-size: 32px;
			line-height: 40px;
		}
	}

	${StyledButtonText} {
		color: white;
	}

	${StyledArrowLinkButton} {
		background-color: ${Color.SecondaryGreen100};
		border: 0;

		:hover:not(:disabled) {
			background-color: #269648;
			border: 0px;
		}

		g {
			stroke: white;
		}
	}

	@media (max-width: 1300px) {
		margin-left: ${NAVIGATION_PADDING_L};
	}

	@media (max-width: 1100px) {
		margin-left: ${NAVIGATION_PADDING_M};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-top: 80px;
		margin-left: ${NAVIGATION_PADDING_S};
		max-width: 90vw;
	}
`;

const StyledBackLink = styled.div<IStyledProps, ITheme>`
	width: auto;

	transform: translateX(-5px);

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		z-index: 2000;
	}

	${StyledLinkText} {
		color: ${themed(({ color }, { isImageHeader }) =>
			isImageHeader ? color.text.inverted : color.text.default,
		)};
	}

	${StyledIconLink} {
		svg {
			margin-left: 0px;
			transform: translateY(2px);
		}
	}
`;

const StyledHeadingWrapper = styled.div<IStyledProps, ITheme>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	z-index: 1;
	margin-top: 120px;
	color: ${themed(({ color }) => color.text.inverted)};

	${ContentWrap} {
		@media (min-width: 1248px) {
			margin-left: unset;
			margin-right: unset;
		}
	}

	${({ isModern, isHomePage }) =>
		isModern
			? css`
					width: 100%;
					margin-top: 140px;
					padding-left: ${NAVIGATION_PADDING_XL};
					padding-right: ${NAVIGATION_PADDING_XL};

					@media (max-width: 1300px) {
						padding-left: ${NAVIGATION_PADDING_L};
						padding-right: ${NAVIGATION_PADDING_L};
					}

					@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
						padding-left: ${NAVIGATION_PADDING_S};
						padding-right: ${NAVIGATION_PADDING_S};
						margin-top: 150px;
					}
			  `
			: // Legacy styles
			  css`
					${sharedWrapperPadding};

					margin-top: ${isHomePage ? '140px' : 'auto'};
					max-width: unset;
					width: 100%;
					margin-bottom: 50px;
			  `}
`;

export const StyledHeaderWrapper = styled.div<IStyledProps>`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	z-index: 0; // This prevents the header from overlapping the search overlay
	height: ${({ isImageHeader, isCtaHeader, isHomePage, isModern }) =>
		isHomePage || isCtaHeader || isModern
			? '780px'
			: isImageHeader
			? '400px'
			: '260px'};
	padding-top: ${({ isImageHeader, isHomePage, isCtaHeader }) =>
		isImageHeader && !isHomePage && !isCtaHeader ? '0' : '80px'};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		height: ${({ isImageHeader, isCtaHeader, isHomePage }) =>
			isCtaHeader
				? '380px'
				: isHomePage
				? '380px'
				: isImageHeader
				? '280px'
				: '180px'};
		padding-top: ${({ isImageHeader, isHomePage, isCtaHeader }) =>
			isImageHeader && !isHomePage && !isCtaHeader ? '0' : '62px'};
	}
`;

const StyledPageTitle = styled.h1<IStyledProps, ITheme>`
	color: ${themed(({ color }, { isImageHeader }) =>
		isImageHeader ? color.text.inverted : color.text.default,
	)};
	font-size: ${({ isHomePage, isModern }) =>
		isHomePage || isModern ? '56px' : '40px'};
	font-weight: 400;
	line-height: normal;
	align-items: center;
	margin-bottom: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: ${({ isHomePage }) => (isHomePage ? '32px' : '24px')};
		line-height: 1.3;
	}
`;

const StyledHeaderImage = styled.img`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const StyledHeaderVideo = styled.video`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const StyledHeaderImageOverlay = styled.div<IStyledProps>`
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
	height: 100%;
	width: 100%;

	${({ isModern }) =>
		isModern
			? css`
					background: linear-gradient(
						90deg,
						rgba(0, 0, 0, 0.15) 25%,
						rgba(0, 0, 0, 0) 50%
					);
			  `
			: css`
					background-color: rgba(0, 0, 0, 0.4);
			  `}
`;

const StyledTopNavigationWrapper = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2000;
	height: 0;
`;

const StyledWebpageIsSecure = styled.a`
	color: ${Color.PrimaryWhite};
	margin-bottom: 0;
	display: inline-flex;
	align-items: center;
	gap: 8px;
	font-weight: bold;
	font-size: 0.9rem;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

const StyledSecurityShieldIcon = styled(SecurityPreferencesIcon)`
	height: 18px;

	path {
		fill: ${Color.PrimaryWhite};
	}
`;

const StyledContentWrap = styled(ContentWrap)`
	width: 100%;
`;
